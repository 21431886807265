import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/allium_logo.png';
import './faq.css';
import './media-queries.css';

class Faq extends React.Component{
  constructor(props) {
    super(props);
  }
  render  () {
     return (
    <div>
        <div className="faqMainWrapper"></div>
          <div className="faqWrapper">
            <div className="logoBox">
              <Link to="/">
                <Image src={logo}  alt="Logo Image" />
              </Link>
            </div> 
            <div className="faqtitle"><h1>Faq</h1></div>
            <div className="faqContent">
              <div className="faqsubcontent">
              <label className="questionlbl">Why is Allium better than a paper door hanger?</label>
              <p className="answerpara">For starters, you never have to worry about running out of Allium’s digital door hangers. You no more having to order more paper hangers and wait for them to be shipped to you. And just as important, you no longer have to worry about having a working pen to leave your comments.</p>
              <p className="answerpara">When your customers are out of town, paper door hangers left on their doors serve as a clear signal to the entire world, no one is home, increasing the risk of your customers’ premises being robbed.</p>
              <p className="answerpara">Paper door hangers do not allow your customers to provide you feedback on your service.  With Allium, if you customers have a question, they can easily contact you.</p>
              <p className="answerpara">Furthermore, paper door hangers cannot include before and after pictures to help your customers better understand the services you have provided them.</p>
              </div>
              <div className="faqsubcontent">
              <label className="questionlbl">What will it cost?</label>
              <p className="answerpara">The introductory offering will be free to send a limited number of door hangers each month. To unlock more advanced features, we will charge a per door hanger price. We are working on exact pricing tiers and will release them soon.</p>
              </div>
              <div className="faqsubcontent">
              <label className="questionlbl">What is the current status of this service?</label>
              <p className="answerpara">We are actively seeking feedback at this point to determine how best to fit it to the market’s needs. Once we hear back from at least 500 customers that they are interested in using this service, we will launch the service into the market.</p>
              </div>
              <div className="faqsubcontent">
              <label className="questionlbl">How do my customers know I was actually at their premises?</label>
              <p className="answerpara">Allium will use your phone's location to ensure the digital door hanger was sent while you are at their premises. Allium users will only be able to send a door hanger from a customer's address. This way your customers will know you were actually on-site to complete the job.</p>
              <p className="answerpara">In addition to this, you can also send before and after pictures on the door hanger to your customer to show them the work you carried out.</p>
              </div>
              <div className="faqsubcontent">
              <label className="questionlbl">What industries can use this digital door hanger?</label>
              <p className="answerpara">Allium is for any industry that wants to better serve customers on-site. This includes, but is not limited to, the following industries: lawn care, swimming pool servicing, gutter cleaning and repair, pest control, sprinkler repair, landscaping, air conditioning and heating, dog walkers, package delivery, tree trimmers, window washers, pet groomers, and security.</p>
              </div>
              <div className="faqsubcontent">
              <label className="questionlbl">How can I sign up for a free trial?</label>
              <p className="answerpara">Go to  <Link to="//GetAllium.com" target="_blank">https://GetAllium.com</Link> and enter your name, your company's name, and your mobile phone number, and select your industry. We will send you an example door hanger. To sign up for a free trial, simply click the "Free Trial".  </p>
              </div>
              <div className="faqsubcontent">
              <label className="questionlbl">How can I get an example door hanger to show my customers?</label>
              <p className="answerpara">Go to <Link to="//GetAllium.com" target="_blank">https://GetAllium.com</Link> and enter your name, your company's name, and your mobile phone number, and then select your industry. We will send you an example door hanger. At the bottom of the example door hanger, we provide you with a link you can send your customers so they can provide you with feedback on the service.</p>
              </div>
            </div> 
          </div>  
     </div> 
    );
  }
}
export default Faq;