import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions'

// const config = {
//   apiKey: "AIzaSyDh3LTrGCnKI7Cik8aLxA1swPG2JOW_eUA",
//   authDomain: "allium-fathom-dev-e2a57.firebaseapp.com",
//   databaseURL: "https://allium-fathom-dev-e2a57.firebaseio.com",
//   projectId: "allium-fathom-dev-e2a57",
//   storageBucket: "allium-fathom-dev-e2a57.appspot.com",
//   messagingSenderId: "771955786230",
//   appId: "1:771955786230:web:b083c453cb883903"
// };
// export const  urll ="https://allium-fathom-dev-e2a57.firebaseapp.com/";

// production

const config = {
  apiKey: "AIzaSyBYZYzGG2rxAQJnPopChOtPWDyRCJ1-04A",
  authDomain: "allium-7e145.firebaseapp.com",
  databaseURL: "https://allium-7e145.firebaseio.com",
  projectId: "allium-7e145",
  storageBucket: "allium-7e145.appspot.com",
  messagingSenderId: "759673759772",
  appId: "1:759673759772:web:15dc99dbb1c45c03"
};
export const  urll ="https://getallium.com/";

firebase.initializeApp(config);
//this.auth = firebase.auth();
export default firebase;