import React from 'react';
import { Image, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/allium_logo.png';
import profileuser from '../../assets/images/profile_user_signup.png';
import firebase from '../../config/firebase';
import {urll} from '../../config/firebase'; 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Moment from 'react-moment';
import { FacebookIcon,FacebookShareButton,TwitterIcon,TwitterShareButton,EmailIcon,EmailShareButton} from 'react-share';
import './landing.css';
import './media-queries.css';
import MetaTags from 'react-meta-tags';
import {isIOS,isAndroid} from "react-device-detect";

class Customerpage extends React.Component{
 
  constructor(props) {
    super(props);
    this.state = { 
      visible: false,
      invite_friend_visible: false,
      errorMessage :"",
      successMessage :"",
      isSignedInNow:false,
      data:"",
      hangerImage:"doorHangerLawn",
      text1:"",
      text2:"",
      text3:"",
      text4:"",
      value: '',
      completedDate:"8/12/19 at 9:30 am",
      nextDate:"8/19/19 at 8:30am",
      copied: false,
      show:true,
      customerUrl:""
      }

  }

  componentDidMount= ()=> 
  {
   // console.log(Platform);
    if( this.props.match.params.uid === undefined && this.props.location.state === undefined )
    {
      this.props.history.push("/");
    }
    else
    {
      let uid =  this.props.location.state? this.props.location.state.response.uid:this.props.match.params.uid;
      
      let obj = firebase.firestore().collection('business').doc(uid);
      obj.get()
      .then(doc => {
        if (!doc.exists) {
          this.props.history.push("/");
        } else {
        let img = "doorHangerPool";
        let text1 = "Basic cleaning, Shock Treatment";
        let text2 = "Stay out of pool till 5pm";
        let text3 = "Backwash Filter";
        let text4 = "Basic Cleaning";
        if(doc.data().businessType==="Lawn Services" || doc.data().businessType==="Other")
        {
          img= "doorHangerLawn";
          text1 = "Lawn mowing, Weed trimming";
          text2 = "Suggest increase watering";
          text3 = "Lawn airation";
          text4 = "Lawn mowing";
        }
        else if(doc.data().businessType==="House Cleaning Services")
        {
          img = "doorHangerMaid";
          text1 = "Basic Cleaning, Carpet shampoo";
          text2 = "Got wine stain up from carpet";
          text3 = "Over cleaning";
          text4 = "Basic cleaning";
        }
        else if(doc.data().businessType==="Pest Control Services")
        {
          img = "doorHangerPest";
          text1 = "Roach prevention";
          text2 = "Noticed fire ant colony in back yard";
          text3 = "Fire ant treatment";
          text4 = "Roach prevention";
        }
          this.setState({
            data:doc.data(),
            hangerImage:img,
            urll:urll+"landingsocial/"+uid,
            customerUrl:urll+"customerpage/"+uid,
            text1:text1,
            text2:text2,
            text3:text3,
            text4:text4, 
            completedDate:doc.data().createdDttm?this.getFormatedDate(doc.data().createdDttm):this.state.completedDate,
            nextDate:doc.data().createdDttm?this.getFormatedDate(doc.data().createdDttm + 604800):this.state.nextDate
          });
        }
      })
      .catch(err => {
        this.props.history.push("/");
      });
    }
  }

  getFormatedDate = (date)=>
  {
    return(<span className="dateSpan">
      <span><Moment unix format="M/D/YY">{date}</Moment></span>
      <span className="atSpan"> at </span>
      <span ><Moment unix  format="hh:mm a">{date}</Moment></span>
    </span>);
  }


  signup = () =>
  {
    //const response = this.props.location.state.response;
    let uid =  this.props.location.state? this.props.location.state.response.uid:this.props.match.params.uid;
    firebase.firestore().collection('business').doc(uid)
          .update({isSignedIn:true})
          .then(querySnapshot => {
                this.setState({visible:true,isSignedInNow:true});
              });
  }

  invite_friend = () => {
    this.setState({invite_friend_visible:true,copied: false});
  }

  handleClose = () => { 
    this.setState({ visible: false });
    this.setState({ invite_friend_visible: false });
  };

  getInitialState() {
    return {value: '', copied: false};
  };


  onChange({target: {value}}) {
    this.setState({value, copied: false});
  };

  onCopy =()=> {
    this.setState({copied: true});
    
  };
  render  () {
    const response = this.state.data?this.state.data:"";    return (
      <div>
      {response.businessName?
        <div>
          <MetaTags>
            <meta name="robots" content="noindex, nofollow, noarchive, noimageindex" />
          </MetaTags>
          <div className="landingWrapper"></div>
          <div className="signupWrapper">
            <div className="logoBox">
              {/* <Link to="/">
                <Image src={logo}  alt="Logo Image" />
                <span className="logoText">Allium</span>
              </Link> */}
            </div>  
            
            <div className="angle-body customerPageWrapper">
              <div className="angle-area">
                <div className="hangerBox doorhanger">
                  <div className={"hangerBoxImageInner "+this.state.hangerImage}  >
                     <div className="hangerTitleContentBox">
                      <div className="hangerContentInnerBox">
                          <h1>
                          {response.businessName?response.businessName:""}
                          </h1>
                          <div className="profil-image">
                            <Image src={profileuser}  alt="Profile User" />
                          </div>
                        </div>
                       </div>
                    <div className="hangerContentBox">
                      
                      <div className="hangerContentInnerBox">
                        <h3>Service completed</h3>
                        <span>{this.state.completedDate}</span>
                        <span>1234 Main Street, Anytown TX 12345</span>
                        <h4>Today's service included</h4>
                        <span>{this.state.text1}</span>
                        <h4>Notes about today</h4>
                        <span>{this.state.text2}</span>
                      </div> 
                      <div className="hangerContentInnerBox nextServiceWrapper">
                        <h3>Your next service is on</h3>
                        <span className="calendarIcon"><span  className="calendarIconInner"><i class="fa fa-calendar" aria-hidden="true"></i></span><span>{this.state.nextDate}</span></span>
                        <h4>We recommend including these services</h4>
                        <span>{this.state.text3}</span>
                        <h4>Upcoming service includes</h4>
                        <span>{this.state.text4}</span>
                      </div>
                      <div className="hangerContentFooterBox">
                        <h3> Thank you for using </h3>
                        <h3 className="capitalword">{response.businessName?response.businessName:""}!</h3>
                        <span>Allium will certify, using the mobile phone’s GPS, that this door hanger will be sent from the customer’s address.</span>
                        
                      </div>   
                    </div> 
                  </div>
                </div>
              </div>
            
             
           
            </div>  
          </div>  
        </div>
        :
        <div className="loadingWrapper">
           <i className="fa fa-spinner fa-spin fa-2x fa-fw"></i>
           <span className="sr-only">Loading...</span>
        </div>
        }
      </div>
    );
  }
}
export default Customerpage;