import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/allium_logo.png';
import './feedback.css';
import './media-queries.css';

class Feedback extends React.Component{
  constructor(props) {
    super(props);
  }
  render  () {
     return (
    <div>
        <div className="feedbackMainWrapper"></div>
          <div className="feedbackWrapper">
            <div className="logoBox">
              <Link to="/">
                <Image src={logo}  alt="Logo Image" />
              </Link>
            </div> 
            <div className="feedbackContent">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfGU-B-OX6wbmswlL-kjxebpVte4FWADrbFkyr2ikODp5EEXw/viewform?embedded=true" width="640" height="1350" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div> 
          </div>  
     </div> 
    );
  }
}
export default Feedback;