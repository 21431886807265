import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route,BrowserRouter,Switch  } from 'react-router-dom';
import signup from './signup/signup';
import landing from './signup/landing';
import faq from './signup/faq';
import feedback from './signup/feedback';
import landingSocial from './signup/landingSocial';
import landingdemo from './signup/landingdemo';
import customerpage from './signup/customerpage';

const  App = () => (
  <BrowserRouter>
  <Switch>
    <Route path="/feedback" exact component={feedback} />
    <Route path="/faq" exact component={faq} />
    <Route path="/landing/:uid" exact component={landing} />
    <Route path="/landingsocial/:uid" exact component={landingSocial} />
    <Route path="/landingdemo/:pagetype/:uid" exact component={landingdemo} />
    <Route path="/landingdemo/:pagetype" exact component={landingdemo} />
    <Route path="/customerpage/:uid" exact component={customerpage} /> 
    <Route path="/:uid" component={signup} exact />
    <Route path="/" component={signup}  />
    </Switch>
  </BrowserRouter> 
  
);
//export default App;
export default App;