import React from 'react';
import { Image, Form, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/allium_logo.png';
import step1 from '../../assets/images/senddoor.png';
import step2 from '../../assets/images/textcontaing.png';
import doorHanger from '../../assets/images/door-hanger.png';
import right1 from '../../assets/images/right1.png';
import right2 from '../../assets/images/right2.png';
import right3 from '../../assets/images/right3.png';
import firebase from '../../config/firebase'; 
import {urll} from '../../config/firebase'; 

import './signup.css';
import './media-queries.css';

class Signup extends React.Component{
  constructor(props) {
    super(props);
    this.state = { 
      visible: false,
      formData:{name:'',businessName:'',businessType:'',mobile:'',otp:'',isVerified:true,createdDttm:0 },
      //companyTypes:[],
      confirmResult:'',
      showStep2: false,
      isSubmitting:false,
      isValid:true,
      isSendingOTP:false,
      isConfirmCode:false,
      isInvalidCode:false,
      isEditMobile:false,
      isBusinessExist:false,
      errorMessage:'',
      isCommonError:false,
      commonError:'',
      isResendingOtp:false,
      isResendOtp:false
    };
    this.countryCode = "+1";
    this.register = this.register.bind(this);
    this.sendOtp = this.sendOtp.bind(this);
    this.confirmCode = this.confirmCode.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
  }

  componentWillMount = () =>
  {
    if(this.props.match.params.uid!== undefined)
    {
      this.getSMSBusiness(this.props.match.params.uid);
    }
  }

  handleOpen = () => { 
    this.setState({ visible: true,isSubmitting:false });
  };

  handleClose = () => { 
    this.setState({ visible: false });
  };

  handleChange = (event) => 
  {
    const { formData } = this.state;
    if(event.target.name === "mobile")
    {
      formData[event.target.name] = event.target.value.replace(/\D/,'')
    }
    else
    {
      formData[event.target.name] = event.target.value;
    }
    this.setState({ formData ,isBusinessExist:false,isCommonError:false});
  }

  register (event)
  {
    event.preventDefault();
    const {formData} = this.state;
    formData['name'] = formData['name'].trim();
    formData['businessName'] = formData['businessName'].trim();
    formData['businessType'] = formData['businessType'].trim();
    formData['mobile'] = formData['mobile'].trim();
    if(formData.name === "" || formData.businessName === "" || formData.businessType === "" || formData.mobile === "" || formData.mobile.length <10 || formData.mobile.length > 10)
    {
      this.setState({isValid:false,isCommonError:false});
    }
    else
    { 
      this.setState({
        formData,
        isSubmitting:true,
        isEditMobile:false,
        showStep2:false,
        isBusinessExist:false,
        isSendingOTP:false,
        isCommonError:false
      });
      this.handleOpen();
    } 
  }

  resendOtp (event)
  {
    event.preventDefault();
      this.setState({isResendingOtp:true,isResendOtp:false });
      this.sendOtp(event);
  }
  sendOtp (event)
  {
    event.preventDefault();
    // if(this.state.showStep2 === true)
    // {
    //   this.setState({isResendingOtp:true,isResendOtp:false });
    // }
      
    let formData ={...this.state.formData};
    if(this.state.formData.mobile.trim() === "" || this.state.formData.mobile.length <10 || this.state.formData.mobile.length > 10)
    {
      this.setState({ errorMessage:"Please enter 10 digit mobile number.",isBusinessExist:true,isResendingOtp:false,isResendOtp:false});
    }
    else
    {
      this.setState({ isSendingOTP:true });
      let sendOTP = firebase.functions().httpsCallable("sendOTP");
      sendOTP({mobile:this.countryCode + formData.mobile})
      .then(res=> {
        if(this.state.isResendingOtp === true)
        {
          this.setState({isResendingOtp:false,isResendOtp:true });
        }
        this.setState({isSendingOTP:false,isEditMobile:false,isBusinessExist:false });  
        //this.otpInput.focus();
      })
      .catch(err=>{
        this.setState({isSendingOTP:false,errorMessage:"Something went wrong. Please try again.",isBusinessExist:true,isResendingOtp:false,isResendOtp:false });
      });
      this.setState({showStep2: true});
    }
  }

  confirmCode (event)
  {
    event.preventDefault();
    this.setState({ isConfirmCode:true });
    const { formData } = this.state;
    if(formData.otp.trim() === "")
    {
      this.setState({ errorMessage:"Please enter verification code.",isBusinessExist:true, isConfirmCode:false,isResendOtp:false});
    }
    else
    {
      firebase.firestore().collection('otp').doc(this.countryCode + formData.mobile)
      .get()
      .then(snapshot => snapshot.data())
      .then(data => {
        if(data.otp == formData.otp && data.verified === false)
        {
          let formData ={...this.state.formData};
          formData.mobile = this.countryCode + formData.mobile;
          formData.createdDttm = parseInt(((new Date()).getTime().toString()).substring(0, 10));
          formData.language = 'English';
          formData.source = 'Website';
          firebase.firestore().collection('business')
                 .add(formData)
                 .then(data => {
                  this.setState({ isConfirmCode:false });
                  formData.uid = data.id;

                  firebase.firestore().collection('business')
                  .doc(data.id)
                 .update({businessUrl:`${urll}landing/${data.id}`})
                 .then(data2 => {
                  this.props.history.push(`landing/${data.id}`,{ response: formData });
                 });
            });

        }
        else
        {
          this.setState({ isConfirmCode:false,errorMessage:"Invalid verification code.",isBusinessExist:true,isResendOtp:false });
        }
      })
      .catch((error) => {
        this.setState({ isConfirmCode:false,errorMessage:"Invalid verification code.",isBusinessExist:true,isResendOtp:false });
      });
    }
  }

  editMobile = () =>
  {
    this.setState({isEditMobile:true});
  }

  getSMSBusiness = (uid) =>
  {
    firebase.firestore().collection("sMSChanelBusiness")
          .doc(uid)
          .get()
          .then(data => {
            console.log(data.data());
              if(data.data()) {
                const { formData } = this.state;
                formData.mobile=data.data().mobile.slice(-10); 
                //formData.name = data.data().smsCode;  
                //formData.businessName = data.data().smsCode;
                formData.businessType=data.data().businessType; 
                this.setState({formData})
              }
          })
          .catch(function(error) {
            console.log("Error getting documents: ", error);
          });
  }

  render () {
    const visible = this.state.visible;
    return (
        <div className="signupWrapper">
          <div className="logoBox">
            <Link to="/">
              <Image src={logo}  alt="Allium" title="Allium" />
             { /*<span className="logoText">Allium</span>*/}
            </Link>
          </div> 
          <div className="contentWrapper">
            <div id="alliumText">
              <h3>Faster, Better, Cheaper</h3>
              <ul>
                  <li><b>.</b>Better communication with your customers</li>
                  <li><b>.</b>Cheaper than old paper door hangers</li>
                  <li><b>.</b>Faster peace of mind for your customer when the job's done</li>
              </ul> 
            </div>
          </div>
          <div className="contentWrapper">
            <div className="desktop-left-section desktop-view">
              <div className="stepBoxOne">
                <Image src={step1}  alt="Step 1" />
                <div className="count">1</div>
              </div>
              <div className="stepBoxTwo">
                <Image src={step2}  alt="Step 2" />
                <div className="count">2</div>
              </div>
              <div className="steps-section">
                <div className="stepContentInner">
                  <h3>Step 1</h3>
                  <p>Allium sends door hanger to your customer.</p>
                </div>
                <div className="stepContentInner">
                  <h3>Step 2</h3>
                  <p> In an instant, your customer gets a text notification that the job has been completed and can view the digital door hanger.</p>
                </div>
                <div className="stepContentInner">
                  <h3>Step 3</h3>
                  <p>There is no step 3. Job done, you're done, your customer is happy.</p>
                </div>
              </div>
            </div>
            <div className="desktop-left-section mobile-view">
              <div className="stepBoxOne">
                <h3>Step 1</h3>
                <p>Allium sends door hanger to your customer.</p>
                <center><Image src={step1}  alt="Step 1" /></center>
                <div className="count">1</div>
              </div>
              <div className="stepBoxTwo">
                <h3>Step 2</h3>
                <p>In an instant, your customer gets a text notification that the job has been completed and can view the digital door hanger.</p>
                <center><Image src={step2}  alt="Step 2" /></center>
                <div className="count">2</div>
              </div>
            </div>
            <div className="desktop-middle-section">
              <h3 className="mobile-view">Step 3</h3>
              <p className="mobile-view">There is no step 3. Job done, you're done, your customer is happy.</p>
              <center><Image src={doorHanger} alt="Pool Cleaning Hanger Image" /></center>
              <div className="count">3</div> 
            </div>
            <div className="desktop-right-section">
              <div className="formBoxWrapper">
                <div id="recaptcha-container" ></div>
                
                  <h3>Preview a digital door hanger for your business</h3>
                  <Form onSubmit={this.register} autoComplete="on" >
                  <Form.Group >
                    <Form.Label>Name</Form.Label>
                    <Form.Control autoCapitalize ="words" autoComplete="given-name" type="text" name="name" onChange={this.handleChange} className={this.state.isValid === false && this.state.formData.name.trim() ===""?"error-box": this.state.formData.name !== ""?"success-box":""} value={this.state.formData.name} />
                    {this.state.isValid === false && this.state.formData.name===""?<i className="fa fa-exclamation-triangle" aria-hidden="true"></i>: this.state.formData.name !== ""?<i className="fa fa-check" aria-hidden="true"></i>:""}
                    <span className="formError">{!this.state.isValid && this.state.formData.name===""?"Please enter name.":"" }</span>
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control autoCapitalize="words" autoComplete="organization" type="text" name="businessName" onChange={this.handleChange} className={this.state.isValid === false && this.state.formData.businessName.trim()===""?"error-box": this.state.formData.businessName !== ""?"success-box":""} value={this.state.formData.businessName} />
                    {this.state.isValid === false && this.state.formData.businessName===""?<i className="fa fa-exclamation-triangle" aria-hidden="true"></i>: this.state.formData.businessName !== ""?<i className="fa fa-check" aria-hidden="true"></i>:""}
                    <span className="formError">{!this.state.isValid && this.state.formData.businessName===""?"Please enter business name.":"" }</span>
                  </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1" >
                      <Form.Label>Business Type</Form.Label>
                      <Form.Control as="select" name="businessType" onChange={this.handleChange} value={this.state.formData.businessType}>
                        <option  value="">Select a Business Type</option> 
                        <option  value="Swimming Pool Services">Swimming Pool Services</option>
                        <option  value="Lawn Services">Lawn Services</option>
                        <option  value="House Cleaning Services">House Cleaning Services</option>
                        <option  value="Pest Control Services">Pest Control Services</option>
                        <option  value="Other">Other</option>
                      </Form.Control>
                      <span className="formError">{!this.state.isValid && this.state.formData.businessType===""?"Please select business type.":"" }</span>
                      </Form.Group>
                    <div className="row">
                    <Form.Group className="col-sm-12">
                      <Form.Label>Mobile #</Form.Label>
                      <Form.Control autoComplete="tel-national"  type="tel" className={"mobileTextBox " +(this.state.isValid === false && (this.state.formData.mobile==="" || this.state.formData.mobile.length <10 || this.state.formData.mobile.length > 10)?"error-box": this.state.formData.name !== "" && this.state.formData.mobile.length === 10?"success-box":"")} name="mobile" value={this.state.formData.mobile} onChange={this.handleChange} />
                      {this.state.isValid === false && (this.state.formData.mobile==="" || this.state.formData.mobile.length <10 || this.state.formData.mobile.length > 10)?<i className="fa fa-exclamation-triangle for-mobile" aria-hidden="true"></i>: this.state.formData.mobile !== "" && this.state.formData.mobile.length === 10?<i className="fa fa-check for-mobile" aria-hidden="true"></i>:""}
                      <span className="formError">{!this.state.isValid && (this.state.formData.mobile.trim()==="" || this.state.formData.mobile.length <10 || this.state.formData.mobile.length > 10)?"Please enter 10 digit mobile number.":"" }</span>
                      </Form.Group>
                    </div>
                    <div className="row mobile-exist" style={{"display":this.state.isCommonError?"block":"none"}}>
                      <Form.Group className="col-sm-12">
                        <span className="formError">{this.state.isCommonError?this.state.commonError:"" }</span>
                      </Form.Group>
                    </div>
                    <div className="previewHangerBox">
                    <Button 
                      variant="primary" 
                      className="previewHangerBtn" 
                     // onKeyPress={() => this.register()} 
                      disabled={this.state.isSubmitting}
                      // onClick={() => this.register()}
                      value="Submit"
                      type="submit"
                      > 
                      {this.state.isSubmitting ? 'Loading…' : 'Preview Door Hanger'}
                    </Button>
                      </div>  
                    </Form> 
                    <div >
                    <Modal show={visible} onHide={this.handleClose} className="modalWrapper" backdrop="true">
                      <Modal.Header closeButton>
                        <Modal.Title style={{ display: !this.state.showStep2 ? 'block': 'none'}} >Verify Your Mobile Number</Modal.Title>
                        <Modal.Title style={{ display: this.state.showStep2 ? 'block': 'none'}} >Enter Verification Code</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="veryfyMoStep1" style={{ display: !this.state.showStep2 ? 'block': 'none'}} >
                            <span className="headerTextTip">In order to create your digital door hanger, we need to send a verification code via SMS to your mobile phone number.</span>
                            <form onSubmit={this.sendOtp} >
                            <div className="verifyEditBox">
                              <div className="mobileIconBox">
                                <i className="fa fa-mobile" aria-hidden="true"></i>
                              </div>
                              
                              {!this.state.isEditMobile?
                              <div className="mobileTextArea">
                                <div className="mobileTextBox">
                                  <label>Mobile #</label>
                                  <span>{this.state.formData.mobile}</span>
                                </div>
                                <div className="verifyEditInnerBtnBox">
                                  <button type="button" className="editBtn" onClick={this.editMobile}>Edit</button>
                                </div>
                                <div>
                                  <span className="formError">{this.state.isBusinessExist=== true?this.state.errorMessage:"" }</span>
                                </div>
                              </div>
                              :
                              <div className="mobileTextBox">
                                <div className="row">
                                  <Form.Group className="col-sm-12">
                                    <Form.Label>Mobile #</Form.Label>
                                    <Form.Control  
                                    ref={(input) => { this.mobileInput = input; }}
                                    autoFocus
                                    type="tel" 
                                    name="mobile" 
                                    className={this.state.isBusinessExist=== true  && (this.state.formData.mobile==="" || this.state.formData.mobile.length <10 || this.state.formData.mobile.length > 10?" error-box": this.state.formData.mobile !== "" && this.state.formData.mobile.length === 10?" success-box":"")} 
                                    value={this.state.formData.mobile} 
                                    onChange={this.handleChange} />
                                    {this.state.isBusinessExist=== true && (this.state.formData.mobile==="" || this.state.formData.mobile.length <10 || this.state.formData.mobile.length > 10)?<i className="fa fa-exclamation-triangle for-edit-mobile" aria-hidden="true"></i>: this.state.formData.mobile !== "" && this.state.formData.mobile.length === 10?<i className="fa fa-check for-edit-mobile" aria-hidden="true"></i>:""}
                                    <span className="formError edit-mobile-formerror">{this.state.isBusinessExist=== true?this.state.errorMessage:"" }</span>
                                    </Form.Group>

                                    
                                </div>
                              </div>
                              }
                              
                            </div>
                            <div className="btnFootWrapper"> 
                            <Button 
                            variant="primary" 
                            className="previewHangerBtn"  
                            disabled={this.state.isSendingOTP}
                            //onKeyPress={() => this.sendOtp()}
                           // onClick={() => this.sendOtp()}
                           type="submit"
                            > 
                            {this.state.isSendingOTP ? 'Sending...' : 'Send Code'}
                            </Button>
                            </div>
                            </form>
                            <div className="errorFootWrapper"> 
                            <span>Message and data rates apply</span>
                            </div>
                        </div>
                        <div className="verifyMoStep2" style={{ display: this.state.showStep2 ? 'block': 'none'}} >
                        <span className="headerTextTip">To continue, please enter the verification code we sent to {this.state.formData.mobile}.</span>              
                        <form onSubmit={this.confirmCode} >
                        <div className="row otpWrapper">
                          <Form.Group className="col-sm-12">
                            <Form.Label>Code</Form.Label>
                            {this.state.showStep2?
                            <Form.Control 
                            autocomplete="one-time-code" 
                            autoFocus
                            type="tel"   
                            name="otp" 
                           // id="otpInput"
                            value={this.state.formData.otp} 
                            onChange={this.handleChange} 
                            className={this.state.isBusinessExist?"error-box": ""}
                             /> :""}
                            {this.state.isBusinessExist?<i className="fa fa-exclamation-triangle for-edit-mobile" aria-hidden="true"></i>:""}
                            <span className="formError">{this.state.isBusinessExist?this.state.errorMessage:"" }</span> 
                            <span className="formError resendotpText">{this.state.isResendOtp?"Verification code has been resent.":"" }</span>
                          </Form.Group>
                        </div>
                        <div className="btnFootWrapper">
                          <button type="button" className="resendBtn" onKeyPress={this.resendOtp} onClick={this.resendOtp}>{this.state.isResendingOtp?"Resending Code...":"Resend Code"}</button>
                        </div>
                        <div className="btnFootWrapper">
                          <Button 
                          variant="primary" 
                          className="previewHangerBtn" 
                          disabled={this.state.isConfirmCode}
                          //onKeyPress={this.confirmCode}
                          type="submit"
                         >
                          {this.state.isConfirmCode ? 'Confirming Code...' : 'Confirm Code'}
                          </Button>
                        </div>
                        </form>
                        </div>
                        </Modal.Body>
                    </Modal>
                  </div> 
                
                </div> 
            </div>
          </div>
          <div className="contentWrapper">
            <div className="bottom-left-section">
              <h3>What is Allium?</h3>
              <p>Allium door hangers improve customer communications in many ways: ​​​​​​​</p>
              <ul>
                <li>Personalized with your company's branding, even add a profile picture and your name.</li>
                <li>Include before and after photos if desired.</li>
                <li>Know which customers viewed their door hanger. </li>
                <li>Suggest additional services for next time. </li>
                <li>Confirm next scheduled visit.</li>
                <li>List other services for customer to review and select if desired. </li>
                <li>Allow customers to send you a brief message.</li>
                <li>Include satisfaction scale to know when there is a problem, and be able to address it promptly.</li>
              </ul>
              <h3>Who is Allium for?</h3>
                            {/*  <div className="formBottomImageBox mobile-view">
                <div className="formBottomImageBoxInner">
                  <Image src={right1} alt="House cleaning" />
                </div>
                <div className="formBottomImageBoxInner">
                  <Image src={right2} alt="Lawn services" />
                </div>
                <div className="formBottomImageBoxInner">
                  <Image src={right3} alt="pool services" />
                </div>
                            </div>*/}
              <p className="no-margin">Allium is great for any company that provides regularly scheduled services such as: ​​​​​​​</p>
              <ul className="no-margin">
                <li>Mowing and landscaping</li>
                <li>House cleaning</li>
                <li>Swimming pool </li>
                <li>Pest control </li>
              </ul>
            </div>
            <div className="bottom-right-section desktop-view">
              <div className="formBottomImageBoxInner"> 
                <Image src={right1}  />
              </div> 
              <div className="formBottomImageBoxInner"> 
                <Image src={right2}  />
              </div> 
              <div className="formBottomImageBoxInner">
                <Image src={right3}  />
              </div> 
            </div>
          </div>
            
          <div className="contentWrapper">
            <div className="footer">
              <div className="leftfooter">
              <a href="/faq">
                FAQ
              </a>
              <div className="dividerLine">|</div>
              <a href="/feedback">
                Feedback
              </a>
              </div> 
              <div className="rightfooter">
               <span>ALLIUM© 2019</span>
              </div> 
              
            </div> 
          </div>
          <div id="crl">
            <div className="background-circle background-circle-1"></div>
            <div className="background-circle background-circle-5"></div>
            <div className="background-circle background-circle-2"></div>
            <div className="background-circle background-circle-3"></div>
            <div className="background-circle background-circle-4"></div>
            <div className="background-circle background-circle-6"></div>
          </div>
        </div>   
    );
  }
}
export default Signup;